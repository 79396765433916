import {Button} from "./components/ui/button";
import {IoIosClose} from "react-icons/io";
import { fabric } from 'fabric';
import {useEffect, useRef, useState} from 'react';
import {GeneratedImageViewerCanvasToolbar} from "./GeneratedImageViewerCanvasToolbar";


interface GeneratedImageViewerProps {
    imageb64: string;
    closeViewer: () => void;
    classes?: string;
}

export const GeneratedImageViewer: React.FC<GeneratedImageViewerProps> = ({ imageb64, closeViewer, classes }) => {
    const canvasRef = useRef(null);

    let canvas: fabric.Canvas | null = null;

    const canvasWidth = 680;
    const canvasHeight = 475;

    useEffect(() => {
        canvas = new fabric.Canvas(canvasRef.current);

        canvas.setHeight(canvasHeight);
        canvas.setWidth(canvasWidth);

        fabric.Image.fromURL(imageb64, function(img) {

            if (!canvas) return;

            // Set the image's dimensions to match the canvas's dimensions
            img.scaleToHeight(canvasHeight);
            img.scaleToWidth(canvasWidth);

            canvas.add(img);
            canvas.renderAll();
        });
    }, [imageb64]);


    function adjustSaturation(value: number) {

        if (!canvas) return;

        const objects = canvas.getObjects();
        for (let i = 0; i < objects.length; i++) {
            const obj = objects[i];
            if (obj instanceof fabric.Image && obj.filters) {

                // Remove the existing saturation filter
                obj.filters = obj.filters.filter(filter => !(filter instanceof fabric.Image.filters.Saturation));

                const filter = new fabric.Image.filters.Saturation({
                    saturation: value
                });
                obj.filters.push(filter);
                obj.applyFilters();
            }
        }
        canvas.renderAll();
    }

    function adjustHue(value: number) {
        console.log('adjusting hue');

        if (!canvas) return;

        const objects = canvas.getObjects();
        for (let i = 0; i < objects.length; i++) {
            const obj = objects[i];
            if (obj instanceof fabric.Image && obj.filters) {

                // Remove the existing saturation filter
                obj.filters = obj.filters.filter(filter => !(filter instanceof fabric.Image.filters.HueRotation));

                const filter = new fabric.Image.filters.HueRotation({
                    rotation: value
                });
                obj.filters.push(filter);
                obj.applyFilters();
            }
        }
        canvas.renderAll();

    }

    let polygon = null;
    let isDrawing = false;

    function polygonLasso() {
        console.log('polygon lasso');

        const canvas = new fabric.Canvas(canvasRef.current);

        // canvas.on('mouse:down', function(options) {
        //     isDrawing = true;
        //     var pointer = canvas.getPointer(options.e);
        //     var points = [ pointer.x, pointer.y, pointer.x, pointer.y ];
        //     polygon = new fabric.Polygon(points, {
        //         fill: '#999999',
        //         opacity: 0.3,
        //         selectable: false
        //     });
        //     canvas.add(polygon);
        // });
        //
        // canvas.on('mouse:move', function(options) {
        //     if (!isDrawing) return;
        //     var pointer = canvas.getPointer(options.e);
        //     polygon.points.push({x: pointer.x, y: pointer.y});
        //     polygon._calcDimensions();
        //     canvas.renderAll();
        // });
        //
        // canvas.on('mouse:up', function() {
        //     isDrawing = false;
        //     canvas.off('mouse:down');
        //     canvas.off('mouse:move');
        //     canvas.off('mouse:up');
        //
        //     // Select all objects within the polygon
        //     var objects = canvas.getObjects();
        //     objects.forEach(function(object) {
        //         if (polygon.containsPoint(object.getCenterPoint())) {
        //             object.set({ active: true });
        //         }
        //     });
        //     canvas.discardActiveObject();
        //     var sel = new fabric.ActiveSelection(objects, { canvas: canvas });
        //     canvas.setActiveObject(sel);
        //     canvas.requestRenderAll();
        // });
    }

    return (
        <div className={"generated-image-viewer-container " + classes}>
            <GeneratedImageViewerCanvasToolbar adjustSaturation={adjustSaturation} adjustHue={adjustHue} polygonLasso={polygonLasso} mode={'abcd'}></GeneratedImageViewerCanvasToolbar>
            <Button style={{position: 'absolute', top: '10px', right: '10px', borderRadius: '50%', height: '40px', width: '40px', padding: '5px', zIndex: '10'}} onClick={closeViewer}><IoIosClose style={{height: '100%', width: '100%'}}></IoIosClose></Button>
            <canvas className={'generated-image-viewer-canvas'} ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
        </div>
    );
};