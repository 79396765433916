import React, {useEffect, useRef} from "react";
import {RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import ReferenceComponent, {ReferenceElement} from "./ReferenceComponent";
// import DropzoneReferences from "./DropzoneReferences";
import Dropzone from "dropzone";
import {addReferenceElement, setShowImageGeneratorEditor} from "../store/slices/workspaceSlice";
import {Util} from "../util";
import {Button} from "src/components/components/ui/button";
import { FaPlus } from "react-icons/fa6";

function ReferencesPanel() {

    const API_ENDPOINT_BASE = useSelector((state: RootState) => state.workspace.apiEndpointBase);

    let referencesArray = useSelector((state: RootState) => state.workspace.references);

    const isDropzoneInitialized = useRef(false);

    const projectID = useSelector((state: RootState) => state.workspace.projectID);
    const referenceElementID = useRef('');
    const userEmail = useSelector((state: RootState) => state.workspace.userEmail);

    const dispatch = useDispatch();

    const menubarHeight = 40;

    const defaultMaxImageWidth = 250;
    const defaultMaxImageHeight = 250;

    const clickX = useRef(0);
    const clickY = useRef(0);

    function dropzoneClick(e: React.MouseEvent) {
        clickX.current = e.clientX;
        clickY.current = e.clientY;
    }


    // For syncing scroll between the references panel and the script and storyboard panels
    useEffect(() => {
        // if there is a scroll on this element, scroll the script and storyboard panels
        let referencesPanelContent = document.querySelector('.references-panel-content');

        if (referencesPanelContent) {
            referencesPanelContent.addEventListener('scroll', (event) => {
                if (!referencesPanelContent) return;

                let scriptPanelContent = document.querySelector('.script-panel-content');
                let storyboardPanelContent = document.querySelector('.storyboards-panel-content');
                scriptPanelContent?.scrollTo(0, referencesPanelContent.scrollTop);
                storyboardPanelContent?.scrollTo(0, referencesPanelContent.scrollTop);
            });
        }
    }, []);

    useEffect(() => {

        if (!isDropzoneInitialized.current) {
            const dzOptions = {
                url: API_ENDPOINT_BASE + "/saveProjectResource",
                disablePreviews: true,
                maxFiles: 1,
                autoProcessQueue: false,
            };

            let myDropzone = new Dropzone("#references-panel-dropzone", dzOptions);
            myDropzone.on("addedfile", file => {
                const reader = new FileReader()
                reader.readAsDataURL(file);

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    let dataStr = reader.result
                    // console.log(dataStr);

                    if (dataStr === null) {
                        dataStr = '';
                    }

                    if (file.type.indexOf('image') > -1) {
                        let img = new Image();
                        img.src = dataStr.toString();
                        img.onload = function() {

                            if (dataStr === null) {
                                return;
                            }

                            const referenceElement = new ReferenceElement();
                            referenceElement.type = file.type;
                            referenceElement.data = encodeURIComponent(dataStr.toString());
                            referenceElement.left = clickX.current;
                            referenceElement.top = clickY.current - menubarHeight;

                            let scaledDimensions = Util.getScaledImageDimensions(img.width, img.height, defaultMaxImageWidth, defaultMaxImageHeight);
                            referenceElement.width = scaledDimensions.width;
                            referenceElement.height = scaledDimensions.height;

                            // add scroll position of the parent element
                            let referencesPanelContent = document.querySelector('.references-panel-content');
                            if (referencesPanelContent && referencesPanelContent instanceof HTMLElement) {
                                referenceElement.top += -referencesPanelContent.offsetTop + referencesPanelContent.scrollTop;
                            }

                            referenceElement.elementID = Util.getRandomID(16);  // let's generate a random ID for every reference element
                            referenceElement.filename = file.name;

                            // update the local variable referenceElementID
                            referenceElementID.current = referenceElement.elementID;

                            dispatch(addReferenceElement({referenceElement: referenceElement}));

                            myDropzone.processQueue();  // this will actually upload the file
                        }
                    }
                }
            });

            myDropzone.on('sending', function(file, xhr, formData) {
                formData.append('projectID', projectID);
                formData.append('resourceType', file.type);
                formData.append('resourceID', referenceElementID.current);
                formData.append('filename', file.name);
                formData.append('userEmail', userEmail);
            });

            myDropzone.on("complete", function(file) {
                myDropzone.removeFile(file);
            });

            isDropzoneInitialized.current = true;
        }

    }, []);

    function handleAddReferenceClick() {
        dispatch(setShowImageGeneratorEditor(true));
    }

    return (
        <div className="panel-container references-panel-container">
            <div className="panel-title-container references-panel-title-container">
                <div className="panel-title references-panel-title mb-2 text-sm leading-6">References</div>
            </div>

            <div className="references-panel-content" id={"references-panel-dropzone"} onClick={dropzoneClick}>

                {referencesArray.length === 0 ? (
                    <div className="empty-references-panel">
                        <div className="empty-references-panel-text">Click anywhere on the references panel or <br></br> the
                            "Add +" button to add a reference image</div>
                    </div>
                ) : null}

                {referencesArray.map((referenceElement: ReferenceElement, index: number) => {
                    return <ReferenceComponent key={index}
                                               data={referenceElement.data}
                                               elementID={referenceElement.elementID}
                                               type={referenceElement.type}
                                               srcPrompt={referenceElement.srcPrompt}
                                               width={referenceElement.width}
                                               height={referenceElement.height}
                                               top={referenceElement.top}
                                               left={referenceElement.left}
                                               transform={referenceElement.transform}
                                               scriptElementId={referenceElement.scriptElementId}
                                               filename={referenceElement.filename}
                                               notes={referenceElement.notes}
                                               tags={referenceElement.tags}
                    ></ReferenceComponent>
                })}

                <div className="dummy-element-for-scroll"></div>
            </div>

            <Button onClick={handleAddReferenceClick} className={'add-reference-button'}><FaPlus></FaPlus></Button>
        </div>
    );
}

export default ReferencesPanel;