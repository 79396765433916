import React, {useState} from 'react';
import logo from './logo.svg';
import '../App.css';
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "./components/ui/carousel";
import {Card, CardContent} from "./components/ui/card";
import {ReferenceElement} from "./ReferenceComponent";
import {RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {setReferenceNotes, setShowReferencesCarousel} from "../store/slices/workspaceSlice";
import ReferencesCarouselCard from "./ReferencesCarouselCard";

function ReferencesCarousel() {

    const referencesArray = useSelector((state: RootState) => state.workspace.references);

    const showReferencesCarousel = useSelector((state: RootState) => state.workspace.showReferencesCarousel);

    const referenceCarouselStartIndex = useSelector((state: RootState) => state.workspace.referenceCarouselStartIndex);

    const dispatch = useDispatch();

    function hideCarousel() {
        dispatch(setShowReferencesCarousel(false));
    }

    return (
        <div className={"references-carousel-container " + (showReferencesCarousel ? '' : 'hidden')}>
            <div className={'references-carousel-backdrop'} onClick={hideCarousel}></div>
            <Carousel className="w-full references-carousel"
                      opts={{
                          startIndex: referenceCarouselStartIndex
                      }}>
                <CarouselContent style={{ height: '100%' }}>
                    {referencesArray.map((referenceElement: ReferenceElement, index: number) => {
                        return <CarouselItem key={index} style={{ height: '100%' }}>
                            {/*<Card style={{ height: '100%' }}>*/}
                            {/*    <CardContent className="flex items-center justify-center p-6 reference-carousel-card-content">*/}
                            {/*        <img src={referenceElement.data} alt={referenceElement.srcPrompt} className="object-contain w-full h-full reference-content-carousel" />*/}
                            {/*        <textarea placeholder={'Click to edit notes'} className={'reference-notes-carousel'} onChange={function(e) {handleNotesChange(e, referenceElement.elementID)}}>{referenceElement.notes}</textarea>*/}
                            {/*    </CardContent>*/}
                            {/*</Card>*/}
                            <ReferencesCarouselCard
                                key={index}
                                data={referenceElement.data}
                                elementID={referenceElement.elementID}
                                type={referenceElement.type}
                                srcPrompt={referenceElement.srcPrompt}
                                width={referenceElement.width}
                                height={referenceElement.height}
                                top={referenceElement.top}
                                left={referenceElement.left}
                                transform={referenceElement.transform}
                                scriptElementId={referenceElement.scriptElementId}
                                filename={referenceElement.filename}
                                notes={referenceElement.notes}
                                tags={referenceElement.tags}
                            ></ReferencesCarouselCard>
                        </CarouselItem>
                    })}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
    );
}

export default ReferencesCarousel;
