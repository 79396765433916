import React from 'react';
import { MdOutlineDraw } from "react-icons/md";
import { GrCursor } from "react-icons/gr";
import { IoArrowUndoSharp } from "react-icons/io5";
import { GrClear } from "react-icons/gr";

interface CanvasToolbarProps {
    onDraw: () => void;
    onErase: () => void;
    onUndo: () => void;
    onClear: () => void;
    mode: string; // Add this line
}

export const DrawingCanvasToolbar: React.FC<CanvasToolbarProps> = ({ onDraw, onErase, onUndo, onClear, mode }) => {
    return (
        <div className="drawing-canvas-toolbar">
            <button onClick={onDraw} className={`drawing-canvas-toolbar-item ${mode === 'draw' ? 'active' : ''}`}><MdOutlineDraw/> <div className={'drawing-canvas-toolbar-item-label'}>Draw</div></button>
            <button onClick={onErase} className={`drawing-canvas-toolbar-item ${mode === 'erase' ? 'active' : ''}`}><GrCursor /> <div className={'drawing-canvas-toolbar-item-label'}>Select</div></button>
            <button onClick={onUndo} className={`drawing-canvas-toolbar-item`}><IoArrowUndoSharp /> <div className={'drawing-canvas-toolbar-item-label'}>Undo</div></button>
            <button onClick={onClear} className={`drawing-canvas-toolbar-item`}><GrClear /> <div className={'drawing-canvas-toolbar-item-label'}>Clear</div></button>
        </div>
);
};