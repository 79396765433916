import React, {useEffect, useState} from 'react';
import './App.css';
import Navbar from "./components/Navbar";
import Panels from "./Panels";
import {useDispatch, useSelector} from "react-redux";
import {
    setAPIEndpointBase,
    setCurrentState,
    setIsStateLoaded,
    setLoginStatus,
    setRunEnvironment
} from "./store/slices/workspaceSlice";
import {Enums} from "./Enums";
import {RootState} from "./store/store";
import Login from "./components/Login";

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "src/components/components/ui/alert-dialog"
import ReferencesCarousel from "./components/ReferencesCarousel";
import ImageGeneratorEditor from "./components/ImageGeneratorEditor";


function App() {

    const API_ENDPOINT_BASE = useSelector((state: RootState) => state.workspace.apiEndpointBase);

    const loginStatus = useSelector((state: RootState) => state.workspace.loginStatus);
    const userEmail = useSelector((state: RootState) => state.workspace.userEmail);
    const dummyProjectID = 'big-fish';

    const dispatch = useDispatch();

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            alert('Please save your work before leaving the page.');
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    // send a request to the server to get state
    useEffect(() => {

        // check if the app is running in development mode by looking at the URL

        if (window.location.href.includes('localhost')) {
            dispatch(setRunEnvironment(Enums.RUN_ENVIRONMENTS.DEVELOPMENT));
            dispatch(setAPIEndpointBase(Enums.API_ENDPOINT_BASE[Enums.RUN_ENVIRONMENTS.DEVELOPMENT]));
        } else {
            dispatch(setRunEnvironment(Enums.RUN_ENVIRONMENTS.PRODUCTION));
            dispatch(setAPIEndpointBase(Enums.API_ENDPOINT_BASE[Enums.RUN_ENVIRONMENTS.PRODUCTION]));
        }

        if (API_ENDPOINT_BASE === '' || loginStatus !== 'logged-in') {
            return;
        }

        fetch(API_ENDPOINT_BASE + '/getProject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'userEmail': userEmail,
                'projectID': dummyProjectID
            }),
            credentials: 'include'
        }).then(response => {
            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                dispatch(setLoginStatus('login-error'));
            }
            return response.text();  // First try to get the text
        })
            .then(data => {
                try {
                    return JSON.parse(data);  // Then try to parse it as JSON
                } catch {
                    throw new Error('Data received is not JSON');
                }
            })
            .then(data => {
                if (typeof data.workspace !== 'undefined') {
                    dispatch(setCurrentState({type: 'LOAD_STATE', data: data}));
                }
                dispatch(setIsStateLoaded(true));
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    });


    function handleAlertDialogAction() {
        window.location.reload();
    }

    let content;

    if (loginStatus === 'logged-out') {
        content = <Login />;
    } else if (loginStatus === 'logged-in') {
        content = (
            <div className={'workspace-container'}>
                <Navbar/>
                <Panels/>
                <ReferencesCarousel></ReferencesCarousel>
                <ImageGeneratorEditor></ImageGeneratorEditor>
            </div>
        );
    } else { // handle the third condition here
        content = (<AlertDialog defaultOpen={true}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Oops!</AlertDialogTitle>
                        <AlertDialogDescription>
                            There was an error loading the project. Please try again later.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogAction onClick={handleAlertDialogAction}>Okay :/</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        );
    }


    return (
        <div className={'App'}>
            {content}
        </div>
    );
}

export default App;
