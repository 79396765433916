import { Button } from "./components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "./components/ui/dialog"
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import {addReferenceElement, addStoryboardElement, setShowImageGeneratorEditor} from "../store/slices/workspaceSlice";
import {DrawingCanvas} from "./DrawingCanvas";
import {Textarea} from "./components/ui/textarea";
import {LineArtGallery} from "./LineArtGallery";
import * as fabric from "fabric";
import {GeneratedImageViewer} from "./GeneratedImageViewer";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import {ReferenceElement} from "./ReferenceComponent";
import {Util} from "../util";
import { Label } from "./components/ui/label"
import { RadioGroup, RadioGroupItem } from "./components/ui/radio-group"
import {StoryboardElement} from "./StoryboardComponent";


export function ImageGeneratorEditor() {

    const API_ENDPOINT_BASE = useSelector((state: RootState) => state.workspace.apiEndpointBase);
    const projectID = useSelector((state: RootState) => state.workspace.projectID);
    const userEmail = useSelector((state: RootState) => state.workspace.userEmail);

    const [open, setOpen] = React.useState(false);
    const showImageGeneratorEditor = useSelector((state: RootState) => state.workspace.showImageGeneratorEditor);

    const imageGeneratorEditorCanvas = useSelector((state: RootState) => state.workspace.imageGeneratorEditorCanvas);
    const [canvasRef, setCanvasRef] = useState<fabric.fabric.Canvas | null>(null);

    const [isImageLoading, setIsImageLoading] = useState(false);

    const [controlImageB64, setControlImageB64] = useState('');

    const imageGenerationOptions = {
        qualityNotSpeed: true
    }

    const promptText = useRef<string>('');

    const dispatch = useDispatch();

    useEffect(() => {
        setOpen(showImageGeneratorEditor);
    }, [showImageGeneratorEditor]);

    function openChangeHandler() {
        setOpen(!open);
        dispatch(setShowImageGeneratorEditor(!open));
    }

    const setCanvasImage = (imageUrl: string) => {
        if (canvasRef) {
            fabric.fabric.Image.fromURL(imageUrl, function(img) {

                if (!canvasRef) return;

                // Calculate the scale factor
                const scaleFactor = canvasRef.height && img.height ? (0.6 * canvasRef.height / img.height) : 1;

                // Scale the image
                img.scale(scaleFactor);

                // Center the image
                img.set({
                    left: canvasRef.width ? canvasRef.width / 2 : 0,
                    top: canvasRef.height ? canvasRef.height / 2 : 0,
                });

                // add image onto canvas (you may want to set its position and other properties too)
                canvasRef.add(img);
                canvasRef.centerObject(img);
                canvasRef.renderAll();

                // deselect selected items
                canvasRef.discardActiveObject();
            });
        }
    };

    useEffect(() => {
        if (imageGeneratorEditorCanvas) {
            setCanvasRef(imageGeneratorEditorCanvas);
        }
    }, [imageGeneratorEditorCanvas]);


    function generateImage() {

        setIsImageLoading(true);
        setControlImageB64(''); // to trigger the closing of the previous image viewer

        const det = 'Scribble_PIDI';
        const b64str = (document.querySelector('.drawing-canvas') as HTMLCanvasElement).toDataURL('image/png').split(',')[1];

        const prompt = (document.querySelector('.prompt-text-area') as HTMLTextAreaElement).value;
        const a_prompt = 'best quality, accurate';
        const n_prompt = 'longbody, lowres, bad anatomy, bad hands, missing fingers, extra digits, fewer digits, cropped, worst quality, low quality';
        const num_samples = 1;
        const image_resolution = 256;
        const detect_resolution = 256;
        const ddim_steps = 10;
        const guess_mode = false;
        const strength = 1;
        const scale = 9;
        const eta = 0.0;

        const control_method = 'scribble';

        const controlnetEndpoint = 'https://gpu.fantail.pro/api/control_s2i';
        // const controlnetEndpoint = 'http://127.0.0.1:5005/api/test';

        // generate a random integer between 1 and 500
        const randomSeed = Math.floor(Math.random() * 500) + 1;

        const params: any = {
            det: det,
            input_image: b64str,
            prompt: prompt,
            a_prompt: a_prompt,
            n_prompt: n_prompt,
            num_samples: num_samples,
            image_resolution: image_resolution,
            detect_resolution: detect_resolution,
            ddim_steps: ddim_steps,
            guess_mode: guess_mode,
            strength: strength,
            scale: scale,
            seed: randomSeed,
            eta: eta,
            control_method: control_method
        }

        promptText.current = prompt;

        // Process the params based on the user preferences.
        if (imageGenerationOptions.qualityNotSpeed) {
            params['ddim_steps'] = 25;
            params['detect_resolution'] = 512;
            params['image_resolution'] = 512;
        } else {
            params['ddim_steps'] = 15;
            params['detect_resolution'] = 256;
            params['image_resolution'] = 256;
        }

        // Convert params object to FormData
        const formData = new FormData();
        for (const key in params) {
            formData.append(key, params[key]);
        }

        fetch(controlnetEndpoint, {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                setIsImageLoading(false);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        }).then(data => {
            console.log(data);
            setControlImageB64('data:image/png;base64,' + data['imageb64']);
            setIsImageLoading(false);
        }).catch(error => {
            console.error('Error:', error);
        });

    }

    function closeViewer() {
        setControlImageB64('');
    }

    async function postImageToServer(b64str: string, elementID: string) {
        // Convert base64 image to a blob
        const fetchImage = async (url: string) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return blob;
        };

        // Use the function to convert base64 image to blob
        const imageBlob = await fetchImage(b64str);

        // Create a new FormData instance
        const formData = new FormData();

        // Append the blob to the FormData instance
        formData.append('file', imageBlob);
        formData.append('projectID', projectID);
        formData.append('resourceType', 'image/png');
        formData.append('resourceID', elementID);
        formData.append('filename', 'generated-image.png');
        formData.append('userEmail', userEmail);

        // Send the POST request to the saveProjectResource endpoint
        fetch(API_ENDPOINT_BASE + '/saveProjectResource', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    }

    function getEditedImageB64() {
        const editedImageCanvas = document.querySelector('.generated-image-viewer-canvas') as HTMLCanvasElement;
        if (!editedImageCanvas) return;

        return 'data:image/png;base64,' + editedImageCanvas.toDataURL('image/png').split(',')[1];
    }

    function addImageToReferences() {

        const editedImageB64 = getEditedImageB64();
        if (!editedImageB64) return;

        let referencesPanelContent = document.querySelector('.references-panel-content');

        const referenceElement = new ReferenceElement();
        referenceElement.type = 'image/png';
        referenceElement.data = encodeURIComponent(editedImageB64.toString());
        referenceElement.left = 10;
        referenceElement.top = 10 + (referencesPanelContent ? referencesPanelContent.scrollTop : 0);
        referenceElement.elementID = Util.getRandomID(16);
        referenceElement.srcPrompt = promptText.current;

        dispatch(addReferenceElement({referenceElement: referenceElement}));

        postImageToServer(editedImageB64, referenceElement.elementID);

        // Close the dialog
        openChangeHandler();

        // Reset the control image
        setControlImageB64('');

        // todo: clear the generated image viewer canvas
    }

    function addImageToStoryboards() {

        const editedImageB64 = getEditedImageB64();
        if (!editedImageB64) return;

        let storyboardsPanelContent = document.querySelector('.storyboards-panel-content');

        const storyboardElement = new StoryboardElement();
        storyboardElement.type = 'image/png';
        storyboardElement.data = encodeURIComponent(editedImageB64.toString());
        storyboardElement.top = 10 + (storyboardsPanelContent ? storyboardsPanelContent.scrollTop : 0);
        storyboardElement.elementID = Util.getRandomID(16);
        storyboardElement.srcPrompt = promptText.current;

        dispatch(addStoryboardElement({storyboardElement: storyboardElement}));

        postImageToServer(editedImageB64, storyboardElement.elementID);

        // Close the dialog
        openChangeHandler();

        // Reset the control image
        setControlImageB64('');

        // todo: clear the generated image viewer canvas
    }

    function handleQualitySpeedChange(e: any) {
        console.log(e);
        imageGenerationOptions.qualityNotSpeed = (e === 'quality');
    }


    return (
        <Dialog open={open} onOpenChange={openChangeHandler}>
            <DialogContent className="image-generator-dialog lg:max-w-[1280px] lg:max-h-[880px]">
                <DialogHeader>
                    <DialogTitle>Create</DialogTitle>
                    <DialogDescription>
                        Create reference image using AI.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex">
                    <GeneratedImageViewer imageb64={controlImageB64} closeViewer={closeViewer} classes={controlImageB64 === '' ? 'hide' : ''}></GeneratedImageViewer>
                    <DrawingCanvas setCanvasImage={setCanvasImage} />
                    <LineArtGallery setCanvasImage={setCanvasImage}></LineArtGallery>

                    {isImageLoading ?
                        <div className={'image-loading-overlay flex justify-center align-middle'} style={{background: "white", position: 'absolute', marginLeft: '78px', height: '480px', width: '682px', border: '0.5px solid lightgrey', alignItems: 'center'}}>
                            <DotLottieReact style={{width: '180px', height: '180px'}}
                                            src={'/loading-bird.json'}
                                            loop={true}
                                            autoplay={true}
                            ></DotLottieReact>
                        </div>
                        : null}
                </div>
                <div className={'image-generator-editor-toolbar'}>
                    <Textarea className={'prompt-text-area'} placeholder={'Enter a prompt...'}>
                    </Textarea>
                    <div className={'flex image-generation-options'} style={{padding: '10px', border: '1px solid lightgrey', borderRadius: '10px'}}>
                        <div className={'image-generation-options-speed-quality flex align-center'}>
                            <RadioGroup defaultValue="quality" className={'flex flex-col justify-center'} onValueChange={handleQualitySpeedChange}>
                                <div className="flex items-center space-x-2">
                                    <RadioGroupItem value="quality" id="r1" />
                                    <Label htmlFor="r1">Quality</Label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <RadioGroupItem value="speed" id="r2" />
                                    <Label htmlFor="r2">Speed</Label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div>
                    <Button style={{height: '100%'}} onClick={generateImage}>Generate</Button>
                </div>
                <DialogFooter className={'sm:justify-start ' + (controlImageB64 === '' ? 'invisible' : 'visible' )} style={{marginLeft: '78px'}}>
                    <Button className={'add-to-reference-btn'} onClick={addImageToReferences}>Add to references</Button>
                    <Button className={'add-to-storyboard-btn'} onClick={addImageToStoryboards}>Add to storyboards</Button>
                </DialogFooter>

            </DialogContent>
        </Dialog>
    )
}

export default ImageGeneratorEditor;