import React, {useEffect, useState} from "react";
import {Card, CardContent} from "src/components/components/ui/card";
import {Button} from "src/components/components/ui/button"
import { FaRegTrashAlt } from "react-icons/fa";
import {useDispatch} from "react-redux";
import {
    deleteReferenceElement,
    setReferenceElementSize,
    setReferenceElementPosition,
    setReferenceNotes,
    setShowReferencesCarousel, setReferenceCarouselStartIndex
} from "../store/slices/workspaceSlice";
import { Rnd } from "react-rnd";
import {Textarea} from "./components/ui/textarea";
import { FaRegEdit } from "react-icons/fa";
import { CiShoppingTag } from "react-icons/ci";


export class ReferenceElement {
    type = '';
    elementID = '';
    srcPrompt = '';
    top = 0;
    left = 0;
    width = 248;
    height = 186;
    transform = '';
    scriptElementId = '';
    data = '';
    filename = '';
    notes = '';
    tags:Array<string> = [];
}


function ReferenceComponent(props: ReferenceElement) {

    const dispatch = useDispatch();

    const [componentNotes, setComponentNotes] = useState(props.notes);

    const [mouseOver, setMouseOver] = useState(false);

    useEffect(() => {
        setComponentNotes(props.notes);
    }, [props.notes]);

    function deleteRefElementHandler() {
        console.log('deleteReferenceElement: ' + props.elementID);
        dispatch(deleteReferenceElement({id: props.elementID}));
    }

    function handleNotesBlur(e: any) {
        dispatch(setReferenceNotes({id: props.elementID, notes: e.target.value}));
    }

    function showReferenceCarousel() {
        dispatch(setReferenceCarouselStartIndex(props.elementID));
        dispatch(setShowReferencesCarousel(true));
    }

    function handleMouseOver() {
        setMouseOver(true)
    }

    function handleMouseOut() {
        setMouseOver(false)
    }

    return (
            <Rnd key={props.elementID}
                 position={{x: props.left, y: props.top}}
                 size={{width: props.width, height: props.height}}
                 maxWidth={250} maxHeight={250}
                 minWidth={100} minHeight={50}
                 lockAspectRatio={true}
                 bounds={'window'}

                 onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={mouseOver ? {zIndex: 15} : {}}

                 onDragStop={(e, d) => {
                     e.preventDefault();
                     e.stopPropagation();
                     const top = d.y;
                     const left = d.x;
                     dispatch(setReferenceElementPosition({id: props.elementID, left: left, top: top}));
                 }}

                 onResizeStop={(e, d, refToElement, delta, position) => {
                     e.preventDefault();
                     e.stopPropagation();

                     if (e.target == null || (e.target as EventTarget & Node).parentElement == null) return;

                     // get the height and width of the element
                     const newWidth = props.width + delta.width;
                     const newHeight = props.height + delta.height;
                     dispatch(setReferenceElementSize({id: props.elementID, width: newWidth, height: newHeight}));
                 }}

                 enableResizing={{top: false, right: true, bottom: true, left: false, topRight: false, bottomRight: true, bottomLeft: false, topLeft: false}}
            >

                <Card className="w-[250px] reference-component">
                    <CardContent>
                        <div className="generated-image reference">
                            { props.tags && props.tags.length > 0 ? <div className={'has-tags-indicator'}><CiShoppingTag></CiShoppingTag></div> : null }
                            <Button className="edit-image-button" onClick={showReferenceCarousel}>
                                <FaRegEdit></FaRegEdit>
                            </Button>
                            <Button className="delete-image-button" onClick={deleteRefElementHandler}>
                                <FaRegTrashAlt></FaRegTrashAlt>
                            </Button>
                            <img src={decodeURIComponent(props.data)} style={{width: "100%;"}} alt=""></img>
                            <div className={"reference-notes-container"}>
                                <Textarea className={'reference-notes-textarea'}
                                          onBlur={handleNotesBlur}
                                          placeholder={'Click to add notes'}
                                          value={componentNotes}
                                          onChange={(e) => setComponentNotes(e.target.value)}>
                                    {componentNotes}
                                </Textarea>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                {/*</ResizableBox>*/}
            </Rnd>
    );
}

export default ReferenceComponent;