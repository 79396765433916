import React, { useEffect, useRef, useState } from 'react';
import * as fabric from 'fabric';
import { DrawingCanvasToolbar } from './DrawingCanvasToolbar';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import {setImageGeneratorEditorCanvas} from "../store/slices/workspaceSlice";


interface FabricJSCanvasProps {
    setCanvasImage: (imageUrl: string) => void;
}

export const DrawingCanvas: React.FC<FabricJSCanvasProps> = ({ setCanvasImage }) => {
    const canvasEl = useRef<HTMLCanvasElement>(null);
    // const canvasRef = useRef<fabric.fabric.Canvas | null>(null);
    const [mode, setMode] = useState('draw'); // Add this line

    const canvasRef = useSelector((state: RootState) => state.workspace.imageGeneratorEditorCanvas);

    const dispatch = useDispatch();

    useEffect(() => {
        const options = { };
        const canvas = new fabric.fabric.Canvas(canvasEl.current, options);
        canvas.isDrawingMode = true;
        dispatch(setImageGeneratorEditorCanvas(canvas));

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Delete' || event.key === 'Backspace') {
                const activeObject = canvas.getActiveObject();
                if (activeObject) {
                    if (activeObject.type === 'activeSelection') {
                        // if it's an active selection, remove all objects in the selection
                        (activeObject as fabric.fabric.ActiveSelection).getObjects().forEach((object: fabric.fabric.Object) => {
                            canvas.remove(object);
                        });
                    } else {
                        // if it's a single object, remove it
                        canvas.remove(activeObject);
                    }
                    // Clear the selection
                    canvas.discardActiveObject().renderAll();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            canvas.dispose();
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleDraw = () => {
        if (canvasRef) {
            canvasRef.isDrawingMode = true;
            setMode('draw'); // Add this line
        }
    };

    const handleErase = () => {
        if (canvasRef) {
            canvasRef.isDrawingMode = false;
            setMode('erase'); // Add this line
        }
    };

    const handleUndo = () => {
        if (canvasRef) {
            const objects = canvasRef.getObjects();
            if (objects.length > 0) {
                canvasRef.remove(objects[objects.length - 1]);
            }
        }
    };

    const handleClear = () => {
        if (canvasRef) {
            const objects = canvasRef.getObjects();
            for (let i = 0; i < objects.length; i++) {
                canvasRef.remove(objects[i]);
            }
            canvasRef.clear().renderAll();
        }
    };

    return (
        <div className={"canvas-and-toolbar-container"}>
            <DrawingCanvasToolbar onDraw={handleDraw} onErase={handleErase} onUndo={handleUndo} onClear={handleClear}
                                  mode={mode}/> {/* Update this line */}
            <canvas className={'drawing-canvas'} width="680" height="475" ref={canvasEl}/>
        </div>
    );
};