import {Button} from "./components/ui/button";

interface ScriptToolbarProps {
    switchToTool: (tool: string) => void;
}

function ScriptToolbar(props: ScriptToolbarProps) {



    return (
        <div className={'script-toolbar-container'}>
            <Button className={'script-toolbar-button'} variant="ghost" onClick={() => {props.switchToTool('transition')}}>
                <div className={'script-toolbar-button-icon'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 10V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H10" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.3333 4.16663H18.3333" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.8333 1.66663V6.66663" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.49992 9.16671C8.42039 9.16671 9.16659 8.42052 9.16659 7.50004C9.16659 6.57957 8.42039 5.83337 7.49992 5.83337C6.57944 5.83337 5.83325 6.57957 5.83325 7.50004C5.83325 8.42052 6.57944 9.16671 7.49992 9.16671Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 12.5001L14.9283 9.92841C14.6158 9.61595 14.1919 9.44043 13.75 9.44043C13.3081 9.44043 12.8842 9.61595 12.5717 9.92841L5 17.5001" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
                Transition</Button>
            <Button className={'script-toolbar-button'} variant="ghost" onClick={() => {props.switchToTool('action')}}>
                <div className={'script-toolbar-button-icon'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.33325 9.16663V15.8333C3.33325 16.2753 3.50885 16.6992 3.82141 17.0118C4.13397 17.3244 4.55789 17.5 4.99992 17.5H14.9999C15.4419 17.5 15.8659 17.3244 16.1784 17.0118C16.491 16.6992 16.6666 16.2753 16.6666 15.8333V9.16663H3.33325Z" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.33324 9.16673L2.5999 6.77506C2.53561 6.5655 2.5133 6.34531 2.53423 6.12712C2.55516 5.90892 2.61892 5.69699 2.72188 5.50347C2.82483 5.30995 2.96494 5.13864 3.1342 4.99935C3.30345 4.86006 3.49852 4.75552 3.70824 4.69173L13.2749 1.77506C13.697 1.64488 14.1536 1.68762 14.5442 1.89388C14.9349 2.10014 15.2277 2.45305 15.3582 2.87506L16.0832 5.26673L3.33324 9.17506V9.16673Z" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.5 4.15833L8.31667 7.65832" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.88354 2.81677L12.7002 6.31677" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
                Action</Button>
            <Button className={'script-toolbar-button'} variant="ghost" onClick={() => {props.switchToTool('character')}}>
                <div className={'script-toolbar-button-icon'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3333 9.16665V9.99998C18.3249 11.6819 17.8077 13.3219 16.8498 14.7044C15.8918 16.0869 14.5379 17.1472 12.9661 17.7458C11.3943 18.3445 9.67813 18.4535 8.0432 18.0585C6.40828 17.6636 4.93114 16.7831 3.80597 15.5329C2.6808 14.2827 1.96027 12.7213 1.73911 11.054C1.51795 9.38663 1.8065 7.69138 2.56684 6.19109C3.32718 4.6908 4.5237 3.45571 5.99912 2.64818C7.47454 1.84064 9.15979 1.49847 10.8333 1.66665" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.66675 11.6666C6.66675 11.6666 7.91675 13.3333 10.0001 13.3333C12.0834 13.3333 13.3334 11.6666 13.3334 11.6666" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 7.5H7.50833" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 7.5H12.5083" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.3333 4.16663H18.3333" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.8333 1.66663V6.66663" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
                Character
            </Button>
            <Button className={'script-toolbar-button'} variant="ghost" onClick={() => {props.switchToTool('dialogue')}}>
                <div className={'script-toolbar-button-icon'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6667 3.99996L3.33333 3.99999C2.22876 3.99999 1.33333 4.89542 1.33333 5.99999V12.6667C1.33333 13.7712 2.22876 14.6667 3.33333 14.6667H10C10.1768 14.6667 10.3464 14.7369 10.4714 14.8619L13.3333 17.7238V15.3333C13.3333 14.9651 13.6317 14.6667 13.9999 14.6667H16.6667C17.7712 14.6667 18.6667 13.7712 18.6667 12.6667V5.99996C18.6667 4.89539 17.7712 3.99996 16.6667 3.99996ZM3.33333 2.66666L16.6667 2.66663C18.5076 2.66662 20 4.15901 20 5.99996V12.6667C20 14.5076 18.5076 16 16.6667 16H14.6666V19.3332C14.6666 19.6029 14.5042 19.846 14.255 19.9492C14.0059 20.0524 13.7192 19.9953 13.5285 19.8047L9.72385 16H3.33333C1.49238 16 0 14.5076 0 12.6667V5.99999C0 4.15904 1.49238 2.66666 3.33333 2.66666Z" fill="#09090B"/></svg>
                </div>
                Dialogue
            </Button>
            <Button className={'script-toolbar-button'} variant="ghost">
                <div className={'script-toolbar-button-icon'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.66675 6.00004C2.66675 5.63185 2.96522 5.33337 3.33341 5.33337H16.6667C17.0349 5.33337 17.3334 5.63185 17.3334 6.00004C17.3334 6.36823 17.0349 6.66671 16.6667 6.66671H3.33341C2.96522 6.66671 2.66675 6.36823 2.66675 6.00004ZM2.66675 10C2.66675 9.63185 2.96522 9.33337 3.33341 9.33337H10.0001C10.3683 9.33337 10.6667 9.63185 10.6667 10C10.6667 10.3682 10.3683 10.6667 10.0001 10.6667H3.33341C2.96522 10.6667 2.66675 10.3682 2.66675 10ZM2.66675 14C2.66675 13.6319 2.96522 13.3334 3.33341 13.3334H14.0001C14.3683 13.3334 14.6667 13.6319 14.6667 14C14.6667 14.3682 14.3683 14.6667 14.0001 14.6667H3.33341C2.96522 14.6667 2.66675 14.3682 2.66675 14Z" fill="#09090B"/></svg>
                </div>
                Other
            </Button>
        </div>
    )
}

export default ScriptToolbar;
