import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import '../App.css';
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "./components/ui/carousel";
import {Card, CardContent} from "./components/ui/card";
import {ReferenceElement} from "./ReferenceComponent";
import {RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {
    addTagToReferenceElement,
    deleteTagFromReferenceElement,
    setReferenceNotes,
    setShowReferencesCarousel
} from "../store/slices/workspaceSlice";
import {Textarea} from "./components/ui/textarea";
import {Input} from "./components/ui/input";
import {Button} from "./components/ui/button";
import { IoIosClose } from "react-icons/io";

function ReferencesCarousel(props: ReferenceElement) {

    const [componentNotes, setComponentNotes] = useState(props.notes);
    let tagInputValue = '';

    useEffect(() => {
        setComponentNotes(props.notes);
    }, [props.notes]);

    const dispatch = useDispatch();

    function handleNotesChange(e: any, elementID: string) {
        dispatch(setReferenceNotes({id: elementID, notes: e.target.value}));
    }

    function tagInputValueChange(e: any) {
        tagInputValue = e.target.value;
    }

    function addTagHandler() {
        // const tag: string = (document.querySelector('.add-tag-input') as HTMLInputElement).value;
        if (tagInputValue === '') {
            return;
        }
        dispatch(addTagToReferenceElement({id: props.elementID, tag: tagInputValue}));
        tagInputValue = '';
    }

    function deleteTagHandler(tag: string) {
        dispatch(deleteTagFromReferenceElement({id: props.elementID, tag: tag}));
    }

    function tagInputKeyDown(e: any) {
        if (e.key === 'Enter') {
            addTagHandler();
            e.target.value = '';
        }
    }

    return (<CarouselItem key={props.elementID} style={{ height: '100%' }}>
                <Card style={{ height: '100%' }}>
                    <CardContent className="flex items-center justify-center p-6 reference-carousel-card-content">
                        <img src={decodeURIComponent(props.data)} alt={props.srcPrompt}
                             className="object-contain w-full h-full reference-content-carousel"/>
                        <div className={'tags-container'}>
                            {props.tags.map((tag, index) => (
                                <div key={index} className={'reference-tag'} onClick={function () {
                                    deleteTagHandler(tag)
                                }}><span>{tag}</span><IoIosClose></IoIosClose></div>
                            ))}
                        </div>
                        <div className={'reference-component-metadata'}>
                            <Textarea placeholder={'Click to edit notes'}
                                      className={'reference-notes-carousel'}
                                      onBlur={function (e) {
                                          handleNotesChange(e, props.elementID)
                                      }}
                                      onChange={function (e) {
                                            setComponentNotes(e.target.value)
                                      }}
                                      value={componentNotes}>{componentNotes}</Textarea>
                            <div className={'tags-toolbar'}>

                                <Input className={'add-tag-input'} placeholder="Add a tag" onKeyDown={tagInputKeyDown} onChange={tagInputValueChange}></Input><Button
                                onClick={addTagHandler} className={'add-tag-button'}>Add</Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
    </CarouselItem>);
}

export default ReferencesCarousel;
