
export const AppCore = (function() {

    function saveProject(state: any, apiEndpointBase: string, userEmail: string) {

        function getTopLeftFromTransform(transform: string) {
            transform = decodeURIComponent(transform);
            transform = transform.replace('translate(', '');
            transform = transform.replace('px', '');
            transform = transform.replace(')', '');
            const translateX = parseFloat(transform.split(',')[0]);
            const translateY = parseFloat(transform.split(',')[1]);
            return {top: translateY, left: translateX};
        }

        function preprocessReferences(references: any) {
            for (let i = 0; i < references.length; i++) {
                const ref = references[i];

                // redact the 'data' field. we don't want to save this in the workspace file. resources are saved separately on the file system.
                ref.data = apiEndpointBase + '/getProjectResource?refID=' + ref.elementID + '&projectID=' + state.projectID + '&userEmail=' + state.userEmail + '&type=' + ref.type;

                // get the transform value of the component
                let transform = ref.transform;

                if (!transform) {
                    continue;
                }

                const tlTransform = getTopLeftFromTransform(transform);

                ref.top = ref.top + tlTransform.top;
                ref.left = ref.left + tlTransform.left;

                ref.transform = '';
            }
        }

        function preprocessStoryboards(storyboards: any) {
            for (let i = 0; i < storyboards.length; i++) {
                const storyboard = storyboards[i];

                // redact the 'data' field. we don't want to save this in the workspace file. resources are saved separately on the file system.
                storyboard.data = apiEndpointBase + '/getProjectResource?refID=' + storyboard.elementID + '&projectID=' + state.projectID + '&userEmail=' + state.userEmail + '&type=' + storyboard.type;

                // get the transform value of the component
                let transform = storyboard.transform;

                if (!transform) {
                    continue;
                }

                const tlTransform = getTopLeftFromTransform(transform);
                storyboard.top = storyboard.top + tlTransform.top;
                storyboard.left = storyboard.left + tlTransform.left;

                storyboard.transform = '';
            }
        }

        try {

            const projectData = {
                projectID: state.projectID,
                references: state.references,
                storyboards: state.storyboards,
                referenceElementID: state.referenceElementID,
                scriptContent: state.scriptContent,
                characters: state.characters
            }

            preprocessReferences(projectData.references);

            preprocessStoryboards(projectData.storyboards);

            fetch(apiEndpointBase + '/saveProject', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'userEmail': userEmail,
                    'workspace': projectData
                }),
                credentials: 'include'
            });
        } catch {
            // ignore write errors
        }
    }


    return {
        saveProject: saveProject,
        // getUserID: getUserID
    }
})();