import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReferencesPanel from "./components/ReferencesPanel";
import ScriptPanel from "./components/ScriptPanel";
import StoryboardPanel from "./components/StoryboardPanel";

function Panels() {
    return (
        <div className="Panels">
            <ReferencesPanel/>
            <ScriptPanel/>
            <StoryboardPanel/>
        </div>
    );
}

export default Panels;
