import {useState} from "react";
import {Input} from "./components/ui/input";
import benchFront from './../line-art/bench-front.png';
import bigTree from './../line-art/big-tree.png';
import busSide from './../line-art/bus-side.png';
import carFront01 from './../line-art/car-front-01.png';
import carFront02 from './../line-art/car-front-02.png';
import carFront03 from './../line-art/car-front-03.png';
import carSide01 from './../line-art/car-side-01.png';
import carSide02 from './../line-art/car-side-02.png';
import chairSide01 from './../line-art/chair-side-01.png';
import chairSide02 from './../line-art/chair-side-02.png';
import circle from './../line-art/circle.png';
import couchFront from './../line-art/couch-front.png';
import coupleSitting01 from './../line-art/couple-sitting-01.png';
import coupleSitting02 from './../line-art/couple-sitting-02.png';
import dinnerTableSide from './../line-art/dinner-table-side.png';
import familyWalking from './../line-art/family-walking.png';
import fatherSonWalking from './../line-art/father-son-walking.png';
import horseGalloping from './../line-art/horse-galloping.png';
import manCloseUp01 from './../line-art/man-close-up-01.png';
import manCloseUp02 from './../line-art/man-close-up-02.png';
import manInWheelchair from './../line-art/man-in-wheelchair.png';
import manLying from './../line-art/man-lying.png';
import manMeditating from './../line-art/man-meditating.png';
import manPlayingGuitar from './../line-art/man-playing-guitar.png';
import manPointingGun01 from './../line-art/man-pointing-gun-01.png';
import manPointingGun02 from './../line-art/man-pointing-gun-02.png';
import manSitting01 from './../line-art/man-sitting-01.png';
import manSitting02 from './../line-art/man-sitting-02.png';
import manSlouching from './../line-art/man-slouching.png';
import manStanding01 from './../line-art/man-standing-01.png';
import manStanding02 from './../line-art/man-standing-02.png';
import manStanding03 from './../line-art/man-standing-03.png';
import palmTree01 from './../line-art/palm-tree-01.png';
import palmTree02 from './../line-art/palm-tree-02.png';
import personCloseUp from './../line-art/person-close-up.png';
import personStanding from './../line-art/person-standing.png';
import seagull from './../line-art/seagull.png';
import smallTree from './../line-art/small-tree.png';
import table from './../line-art/table.png';
import womanCloseUp01 from './../line-art/woman-close-up-01.png';
import womanCloseUp02 from './../line-art/woman-close-up-02.png';
import womanSitting01 from './../line-art/woman-sitting-01.png';
import womanSitting02 from './../line-art/woman-sitting-02.png';
import womanStanding from './../line-art/woman-standing.png';
import womanWalking from './../line-art/woman-walking.png';

interface LineArtGridProps {
    setCanvasImage: (imageUrl: string) => void;
}

export const LineArtGallery: React.FC<LineArtGridProps> = ({ setCanvasImage }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const lineArtItems = [
        { id: 'bench-front', src: benchFront, 'name': 'bench front view' },
        { id: 'big-tree', src: bigTree, 'name': 'big deciduous tree with large canopy' },
        { id: 'bus-side', src: busSide, 'name': 'bus side view' },
        { id: 'car-front-01', src: carFront01, 'name': 'sedan car front view 01' },
        { id: 'car-front-02', src: carFront02, 'name': 'sedan car front view 02' },
        { id: 'car-front-03', src: carFront03, 'name': 'sedan car front view 03' },
        { id: 'car-side-01', src: carSide01, 'name': 'sedan car side view 01' },
        { id: 'car-side-02', src: carSide02, 'name': 'sedan car side view 02' },
        { id: 'chair-side-01', src: chairSide01, 'name': 'dining chair side view facing left' },
        { id: 'chair-side-02', src: chairSide02, 'name': 'dining chair side view facing right' },
        { id: 'circle', src: circle, 'name': 'sphere circle sun ball' },
        { id: 'couch-front', src: couchFront, 'name': 'couch front view' },
        { id: 'couple-sitting-01', src: coupleSitting01, 'name': 'back view of a couple sitting next to each other' },
        { id: 'couple-sitting-02', src: coupleSitting02, 'name': 'silhouette of a couple sitting together with the woman leaning her head on the mans shoulder' },
        { id: 'dinner-table-side', src: dinnerTableSide, 'name': 'round dinner table side view with table cloth' },
        { id: 'family-walking', src: familyWalking, 'name': 'a family of three with a mom a dad and a young boy son' },
        { id: 'father-son-walking', src: fatherSonWalking, 'name': 'a father and a young boy son walking together with the fathers hand placed on the sons head' },
        { id: 'horse-galloping', src: horseGalloping, 'name': 'horse galloping or running slowly' },
        { id: 'man-close-up-01', src: manCloseUp01, 'name': 'close up of a short hair mans face and neck shoulders and chest' },
        { id: 'man-close-up-02', src: manCloseUp02, 'name': 'close up of the face of a man wearing a fedora hat' },
        { id: 'man-in-wheelchair', src: manInWheelchair, 'name': 'front view of a man with disability sitting in a wheelchair' },
        { id: 'man-lying', src: manLying, 'name': 'front view of a man lying down in fetal position' },
        { id: 'man-meditating', src: manMeditating, 'name': 'man meditating' },
        { id: 'man-playing-guitar', src: manPlayingGuitar, 'name': 'man playing guitar standing up' },
        { id: 'man-pointing-gun-01', src: manPointingGun01, 'name': 'close up of a man with short hair pointing a gun 01' },
        { id: 'man-pointing-gun-02', src: manPointingGun02, 'name': 'close up of a man with short hair pointing a gun 02' },
        { id: 'man-sitting-01', src: manSitting01, 'name': 'side view of an old man sitting on the ground' },
        { id: 'man-sitting-02', src: manSitting02, 'name': 'side view of a man sitting in a dining chair' },
        { id: 'man-slouching', src: manSlouching, 'name': 'man standing with a slouching hunched back' },
        { id: 'man-standing-01', src: manStanding01, 'name': 'side view of a man standing' },
        { id: 'man-standing-02', src: manStanding02, 'name': 'a man proudly standing with his hands on his hips and his legs apart' },
        { id: 'man-standing-03', src: manStanding03, 'name': 'a man casually standing' },
        { id: 'palm-tree-01', src: palmTree01, 'name': 'a tall tropical palm tree' },
        { id: 'palm-tree-02', src: palmTree02, 'name': 'a small tropical palm tree with coconut fruits hanging on it' },
        { id: 'person-close-up', src: personCloseUp, 'name': 'a close up of a person man woman looking down into the camera' },
        { id: 'person-standing', src: personStanding, 'name': 'a person man or woman with long hair standing casually' },
        { id: 'seagull', src: seagull, 'name': 'seagull gliding flying' },
        { id: 'small-tree', src: smallTree, 'name': 'small lean deciduous tree with leaves' },
        { id: 'table', src: table, 'name': 'side view of a square table' },
        { id: 'woman-close-up-01', src: womanCloseUp01, 'name': 'close up head face of a woman with long hair 01' },
        { id: 'woman-close-up-02', src: womanCloseUp02, 'name': 'close up head face of a woman with long hair 02' },
        { id: 'woman-sitting-01', src: womanSitting01, 'name': 'side view of a woman sitting in a chair 01' },
        { id: 'woman-sitting-02', src: womanSitting02, 'name': 'side view of a woman sitting in a chair 02' },
        { id: 'woman-standing', src: womanStanding, 'name': 'side view of a woman standing' },
        { id: 'woman-walking', src: womanWalking, 'name': 'side view of a woman walking' },

    ]

    const filteredLineArts = lineArtItems.filter(lineArt =>
        lineArt.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className={'line-art-gallery'}>
            <Input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <div className="line-art-grid">
                {filteredLineArts.map((lineArt, index) => (
                    <div key={index} className="line-art-item" onClick={() => setCanvasImage(lineArt.src)}>
                        <img src={lineArt.src} alt=""/>
                    </div>
                ))}
            </div>
        </div>
    );
};