import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarShortcut,
    MenubarTrigger,
} from "src/components/components/ui/menubar"
import {editor} from "./ScriptPanel";
import {useDispatch, useSelector} from "react-redux";
import {saveState, setWritingMode} from "../store/slices/workspaceSlice";
import {RootState} from "../store/store";
import { FaCircle } from "react-icons/fa";

function Navbar() {

    const writingMode = useSelector((state: RootState) => state.workspace.writingMode);

    const dispatch = useDispatch();

    function saveHandler() {
        console.log('saveHandler');
        editor.save().then((outputData) => {
            dispatch(saveState({scriptContent: outputData}));
        });
    }

    function logoutHandler() {
        window.location.reload();
    }

    function switchToFocusedMode() {
        dispatch(setWritingMode('focused'));
        document.querySelector('.App')?.classList.add('writing-mode-focused');
        document.querySelector('.App')?.classList.remove('writing-mode-normal');
    }

    function switchToNormalMode() {
        dispatch(setWritingMode('normal'));
        document.querySelector('.App')?.classList.add('writing-mode-normal');
        document.querySelector('.App')?.classList.remove('writing-mode-focused');
    }


    return (
        <Menubar>
            <MenubarMenu>
                <MenubarTrigger>File</MenubarTrigger>
                <MenubarContent>
                    <MenubarItem onClick={saveHandler}>
                        Save <MenubarShortcut>⌘S</MenubarShortcut>
                    </MenubarItem>
                    <MenubarItem onClick={logoutHandler}>
                        Logout
                    </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
            <MenubarMenu>
                <MenubarTrigger>Mode</MenubarTrigger>
                <MenubarContent>
                    <MenubarItem onClick={switchToNormalMode}>
                        {writingMode === 'normal' ? <span style={{'marginRight': '10px'}}><FaCircle/></span> :
                            <span style={{'marginRight': '10px', visibility: 'hidden'}}><FaCircle/></span>}
                        Default
                    </MenubarItem>
                    <MenubarItem onClick={switchToFocusedMode}>
                        {writingMode === 'focused' ? <span style={{'marginRight': '10px'}}><FaCircle/></span> :
                            <span style={{'marginRight': '10px', visibility: 'hidden'}}><FaCircle/></span>}
                        Focused
                    </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
        </Menubar>
    )
}

export default Navbar;
