import {nanoid} from 'nanoid';


export const Util = (function() {

    function getRandomID(len: number) {
        return nanoid(10);
    }

    function getScaledImageDimensions(width: number, height: number, maxWidth: number, maxHeight: number) {
        let newWidth = width;
        let newHeight = height;
        let scaleFactor = 1.0;

        if (width > height) {
            if (width > maxWidth) {
                scaleFactor = maxWidth / width;
                newWidth = width * scaleFactor;
                newHeight = height * scaleFactor;
            }
        } else {
            if (height > maxHeight) {
                scaleFactor = maxHeight / height;
                newWidth = width * scaleFactor;
                newHeight = height * scaleFactor;
            }
        }

        return {width: newWidth, height: newHeight};
    }


    return {
        getRandomID: getRandomID,
        getScaledImageDimensions: getScaledImageDimensions
    }
})();