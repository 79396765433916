import React, {useEffect, useRef} from "react";
import EditorJS, {BlockToolData, OutputData} from '@editorjs/editorjs';
import { ToolConstructable } from '@editorjs/editorjs';
import {EditorPlugins} from "../editor-plugins";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import ScriptToolbar from "./ScriptToolbar";

export let editor: EditorJS;

function ScriptPanel() {

    // This is a workaround for the EditorJS issue where the editor is not re-initialized when the component is re-rendered.
    // https://github.com/codex-team/editor.js/issues/2154
    const isEditorInitialized = useRef(false);

    const isStateLoaded = useSelector((state: RootState) => state.workspace.isStateLoaded);
    const scriptContent = useSelector((state: RootState) => state.workspace.scriptContent);

    useEffect(() => {
        // if there is a scroll on this element, scroll the script and storyboard panels
        let scriptPanelContent = document.querySelector('.script-panel-content');

        if (scriptPanelContent) {
            scriptPanelContent.addEventListener('scroll', (event) => {
                if (!scriptPanelContent) return;

                let referencesPanelContent = document.querySelector('.references-panel-content');
                let storyboardPanelContent = document.querySelector('.storyboards-panel-content');
                referencesPanelContent?.scrollTo(0, scriptPanelContent.scrollTop);
                storyboardPanelContent?.scrollTo(0, scriptPanelContent.scrollTop);
            });
        }
    }, []);

    useEffect(() => {
        if (isStateLoaded && !isEditorInitialized.current) {
            editor = new EditorJS({
                /**
                 * ID of Element that should contain the Editor
                 */
                holder: 'script-editor',
                tools: {
                    dialogue: {
                        class: EditorPlugins.Dialogue as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+D',
                        config: {
                            placeholder: 'Enter a dialogue',
                        }
                    },
                    character: {
                        class: EditorPlugins.Character as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+C',
                        config: {
                            placeholder: 'Enter a character',
                        }
                    },
                    transition: {
                        class: EditorPlugins.Transition as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+F',
                        config: {
                            placeholder: 'Enter a transition',
                        }
                    },
                    parenthetical: {
                        class: EditorPlugins.Parenthetical as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+P',
                        config: {
                            placeholder: 'Enter a parenthetical',
                        }
                    },
                    action: {
                        class: EditorPlugins.Action as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+A',
                        config: {
                            placeholder: 'Enter an action',
                        }
                    },
                    shot: {
                        class: EditorPlugins.Shot as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+S',
                        config: {
                            placeholder: 'Enter a shot',
                        }
                    },
                    lyrics: {
                        class: EditorPlugins.Lyrics as unknown as ToolConstructable,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+L',
                        config: {
                            placeholder: 'Enter lyrics',
                        }
                    },

                    // ...
                },
                data: scriptContent,
                // onsReady: () => {
                //     new Undo({ editor });
                // },
                hideToolbar: true,
            });
            isEditorInitialized.current = true;
        }

    }, [isStateLoaded]);


    function switchToTool(tool: string) {

        const currentBlockIdx = editor.blocks.getCurrentBlockIndex();
        const currentBlock = editor.blocks.getBlockByIndex(currentBlockIdx);
        if (currentBlock) {
            currentBlock.save().then((savedData) => {
                if (savedData) {
                    editor.blocks.delete();
                    editor.blocks.insert(tool, {text: savedData.data.text});
                    let isCaretSet = editor.caret.setToPreviousBlock();
                    console.log(isCaretSet); // should log if false
                    isCaretSet = editor.caret.setToNextBlock('end');
                    console.log(isCaretSet); // should log if false
                }
            });
        } else {
            editor.blocks.delete(currentBlockIdx);
            editor.blocks.insert(tool, {
                type: tool,
                data: {text: ''},
                needToFocus: true
            });
        }

        // const newBlock = editor.blocks.composeBlockData(tool);
        // newBlock.then((data) => {
        //    console.log(data);
        // });
    }


    return (
        <div className="panel-container script-panel-container">
            <div className="panel-title-container script-panel-title-container">
                <div className="panel-title script-panel-title mb-2 text-sm leading-6">Script</div>
            </div>

            <div className="script-panel-content" id="script-editor">
                <div className="dummy-element-for-scroll"></div>
            </div>

            <div className="audio-player-container template">
                <div className="audio-player-button-container">
                    <div className="audio-player-button">
                        {/*<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"*/}
                        {/*     x="0px" y="0px"*/}
                        {/*     viewBox="0 0 32 40" enable-background="new 0 0 32 32" xml:space="preserve"><g><g><path d="M4.993,2.496C4.516,2.223,4,2.45,4,3v26c0,0.55,0.516,0.777,0.993,0.504l22.826-13.008    c0.478-0.273,0.446-0.719-0.031-0.992L4.993,2.496z"></path><path*/}
                        {/*    d="M4.585,30.62L4.585,30.62C3.681,30.62,3,29.923,3,29V3c0-0.923,0.681-1.62,1.585-1.62c0.309,0,0.621,0.085,0.904,0.248    l22.794,13.007c0.559,0.319,0.878,0.823,0.878,1.382c0,0.548-0.309,1.039-0.847,1.347L5.488,30.373    C5.206,30.534,4.894,30.62,4.585,30.62z M5,3.651v24.698l21.655-12.34L5,3.651z"/></g></g></svg>*/}
                    </div>
                </div>
                <audio className="audio-player"></audio>
            </div>

            <ScriptToolbar switchToTool={switchToTool}></ScriptToolbar>
        </div>
    );
}

export default ScriptPanel;