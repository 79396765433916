export const Enums = (function() {

    interface APIEndpointBase {
        [key: string]: string;
    }

    const RUN_ENVIRONMENTS = {
        DEVELOPMENT: 'development',
        PRODUCTION: 'production',
        STAGING: 'staging'
    }

    const API_ENDPOINT_BASE: APIEndpointBase = {}
    API_ENDPOINT_BASE[RUN_ENVIRONMENTS.DEVELOPMENT] = 'http://localhost:3000';
    API_ENDPOINT_BASE[RUN_ENVIRONMENTS.PRODUCTION] = 'https://api.fantail.pro';

    return {
        RUN_ENVIRONMENTS: RUN_ENVIRONMENTS,
        API_ENDPOINT_BASE: API_ENDPOINT_BASE
    }
})();