import React, {useEffect, useRef} from "react";
import {Button} from "./components/ui/button";
import {addStoryboardElement, setShowImageGeneratorEditor} from "../store/slices/workspaceSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import Dropzone from "dropzone";
import StoryboardComponent, {StoryboardElement} from "./StoryboardComponent";
import {Util} from "../util";
import { FaPlus } from "react-icons/fa6";

function StoryboardPanel() {

    const dispatch = useDispatch();

    const API_ENDPOINT_BASE = useSelector((state: RootState) => state.workspace.apiEndpointBase);

    let storyboardsArray = useSelector((state: RootState) => state.workspace.storyboards);
    const isDropzoneInitialized = useRef(false);

    const projectID = useSelector((state: RootState) => state.workspace.projectID);
    const storyboardElementID = useRef('');
    const userEmail = useSelector((state: RootState) => state.workspace.userEmail);

    const menubarHeight = 40;

    const clickX = useRef(0);
    const clickY = useRef(0);

    function dropzoneClick(e: React.MouseEvent) {
        clickX.current = e.clientX;
        clickY.current = e.clientY;
    }

    useEffect(() => {
        // if there is a scroll on this element, scroll the script and storyboard panels
        let storyboardPanelContent = document.querySelector('.storyboards-panel-content');

        if (storyboardPanelContent) {
            storyboardPanelContent.addEventListener('scroll', (event) => {
                if (!storyboardPanelContent) return;

                let referencesPanelContent = document.querySelector('.references-panel-content');
                let scriptPanelContent = document.querySelector('.script-panel-content');
                referencesPanelContent?.scrollTo(0, storyboardPanelContent.scrollTop);
                scriptPanelContent?.scrollTo(0, storyboardPanelContent.scrollTop);
            });
        }
    }, []);

    useEffect(() => {

        if (!isDropzoneInitialized.current) {
            const dzOptions = {
                url: API_ENDPOINT_BASE + "/saveProjectResource",
                disablePreviews: true,
                maxFiles: 1,
                autoProcessQueue: false,
            };

            let myDropzone = new Dropzone("#storyboards-panel-dropzone", dzOptions);
            myDropzone.on("addedfile", file => {
                const reader = new FileReader()
                reader.readAsDataURL(file);

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    let dataStr = reader.result
                    // console.log(dataStr);

                    if (dataStr === null) {
                        dataStr = '';
                    }

                    if (file.type.indexOf('image') > -1) {
                        let img = new Image();
                        img.src = dataStr.toString();
                        img.onload = function() {

                            if (dataStr === null) {
                                return;
                            }

                            const storyboardElement = new StoryboardElement();
                            storyboardElement.type = file.type;
                            storyboardElement.data = encodeURIComponent(dataStr.toString());
                            storyboardElement.top = clickY.current - menubarHeight;

                            // add scroll position of the parent element
                            let storyboardsPanelContent = document.querySelector('.storyboards-panel-content');
                            if (storyboardsPanelContent && storyboardsPanelContent instanceof HTMLElement) {
                                storyboardElement.top += -storyboardsPanelContent.offsetTop + storyboardsPanelContent.scrollTop;
                            }

                            storyboardElement.elementID = Util.getRandomID(16);  // let's generate a random ID for every storyboard element
                            storyboardElement.filename = file.name;

                            // update the local variable storyboardElementID
                            storyboardElementID.current = storyboardElement.elementID;

                            dispatch(addStoryboardElement({storyboardElement: storyboardElement}));

                            myDropzone.processQueue();  // this will actually upload the file
                        }
                    }
                }
            });

            myDropzone.on('sending', function(file, xhr, formData) {
                formData.append('projectID', projectID);
                formData.append('resourceType', file.type);
                formData.append('resourceID', storyboardElementID.current);
                formData.append('filename', file.name);
                formData.append('userEmail', userEmail);
            });

            myDropzone.on("complete", function(file) {
                myDropzone.removeFile(file);
            });

            isDropzoneInitialized.current = true;
        }

    }, []);


    function handleAddStoryboardClick() {
        dispatch(setShowImageGeneratorEditor(true));
    }

    return (
        <div className="panel-container storyboard-panel-container">
            <div className="panel-title-container storyboard-panel-title-container">
                <div className="panel-title storyboard-panel-title mb-2 text-sm leading-6">Storyboards</div>
            </div>
            <div className="storyboards-panel-content" id={"storyboards-panel-dropzone"} onClick={dropzoneClick}>

                {storyboardsArray.length === 0 ? (
                    <div className="empty-storyboards-panel">
                        <div className="empty-storyboards-panel-text">Click anywhere on the storyboards panel or <br></br> the
                            "Add +" button to add a storyboard image</div>
                    </div>
                ) : null}

                {storyboardsArray.map((storyboardElement: StoryboardElement, index: number) => {
                    return <StoryboardComponent key={index}
                                               data={storyboardElement.data}
                                               elementID={storyboardElement.elementID}
                                               type={storyboardElement.type}
                                               srcPrompt={storyboardElement.srcPrompt}
                                               width={storyboardElement.width}
                                               height={storyboardElement.height}
                                               top={storyboardElement.top}
                                               left={storyboardElement.left}
                                               transform={storyboardElement.transform}
                                               scriptElementId={storyboardElement.scriptElementId}
                                               filename={storyboardElement.filename}
                                               notes={storyboardElement.notes}
                                               tags={storyboardElement.tags}
                    ></StoryboardComponent>
                })}
                <div className="dummy-element-for-scroll"></div>
            </div>

            <Button onClick={handleAddStoryboardClick} className={'add-storyboard-button'}><FaPlus></FaPlus></Button>
        </div>
    );
}

export default StoryboardPanel;