import React, {useState} from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setLoginStatus, setUserEmail} from "../store/slices/workspaceSlice";
import {RootState} from "../store/store";


axios.defaults.withCredentials = true;

const GoogleAuth = () => {

    const [profile, setProfile] = useState({
        name: '',
        email: ''
    });

    const API_ENDPOINT_BASE = useSelector((state: RootState) => state.workspace.apiEndpointBase);

    const dispatch = useDispatch();

    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const response = await axios.post(API_ENDPOINT_BASE + '/auth/google', {  // http://localhost:3001/auth/google backend that will exchange the code
                code,
            });

            const email = response.data.email;
            const name = response.data.name;

            setProfile({
                'name': name,
                'email': email
            });

            dispatch(setLoginStatus('logged-in'));
            dispatch(setUserEmail(email));
        },
        flow: 'auth-code',
    });

    return (
        <div>
            {profile.name && profile.name.length > 0 ? (
                <div>
                    <p>Email Address: {profile.email}</p>
                </div>
            ) : (
                <button onClick={googleLogin} className={"g-id-sign-in px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150"}>
                    <img className={"w-6 h-6"} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"></img>
                    <span>Login with Google</span>
                </button>
                )}
        </div>
    );
};

export default GoogleAuth;