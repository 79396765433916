import GoogleAuth from "./GoogleAuth";
import logo from './images/fantail-logo.png';


const Login = () => {
    return (
        <div className={'login-panel-container'}>
            <div className={'login-panel'}>
                <div className="logo">
                    <img src={logo} alt="logo" width="100%"/>
                </div>

                <GoogleAuth></GoogleAuth>
            </div>
        </div>
    );
}

export default Login;