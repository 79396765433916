import {createSlice} from "@reduxjs/toolkit";
import {ReferenceElement} from "../../components/ReferenceComponent";
import {OutputData} from "@editorjs/editorjs";
import {Enums} from "../../Enums";
import {AppCore} from "../../app-core";
import {fabric} from "fabric";

interface CurrentState {
    runEnvironment: string;
    apiEndpointBase: string;
    isStateLoaded: boolean;
    loginStatus: string;
    userEmail: string;
    projectID: string;
    references: ReferenceElement[];
    storyboards: any[];
    referenceElementID: number;
    scriptContent: OutputData;
    characters: [];
    showReferencesCarousel: boolean;
    showStoryboardCarousel: boolean;
    referenceCarouselStartIndex: number;
    storyboardCarouselStartIndex: number;
    showImageGeneratorEditor: boolean;
    imageGeneratorEditorID: string;
    writingMode: string;
    imageGeneratorEditorCanvas: fabric.Canvas | null;
}

const initialState: CurrentState = {
    runEnvironment: Enums.RUN_ENVIRONMENTS.DEVELOPMENT,
    apiEndpointBase: '',
    loginStatus: 'logged-out',
    isStateLoaded: false,
    userEmail: 'dummy-user',
    projectID: 'big-fish',
    references: [],
    storyboards: [],
    referenceElementID: 0,
    scriptContent: {
        time: 0,
        blocks: [],
        version: '2.29.1'
    },
    characters: [],
    showReferencesCarousel: false,
    showStoryboardCarousel: false,
    referenceCarouselStartIndex: 0,
    storyboardCarouselStartIndex: 0,
    showImageGeneratorEditor: false,
    imageGeneratorEditorID: '',
    writingMode: 'normal',
    imageGeneratorEditorCanvas: null
};

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        addReferenceElement: (state, action) => {

            if (state.references.length === 0) {
                state.references.push(action.payload.referenceElement);
                return;
            }

            let isElementAdded = false

            for (let i = 0; i < state.references.length; i++) {
                if (state.references[i].top < action.payload.referenceElement.top) {
                    continue;
                } else {
                    state.references.splice(i, 0, action.payload.referenceElement);
                    isElementAdded = true
                    break;
                }
            }

            if (!isElementAdded) {
                state.references.push(action.payload.referenceElement);
            }
        },
        addStoryboardElement: (state, action) => {

            if (state.storyboards.length === 0) {
                state.storyboards.push(action.payload.storyboardElement);
                return;
            }

            let isElementAdded = false

            for (let i = 0; i < state.storyboards.length; i++) {
                if (state.storyboards[i].top < action.payload.storyboardElement.top) {
                    continue;
                } else {
                    state.storyboards.splice(i, 0, action.payload.storyboardElement);
                    isElementAdded = true
                    break;
                }
            }

            if (!isElementAdded) {
                state.storyboards.push(action.payload.storyboardElement);
            }
        },
        deleteReferenceElement: (state, action) => {
            const result = [];

            for (let i = 0; i < state.references.length; i++) {
                if (state.references[i].elementID !== action.payload.id) {
                    result.push(state.references[i]);
                }
            }

            state.references = result;
        },
        deleteStoryboardElement: (state, action) => {
            const result = [];

            for (let i = 0; i < state.storyboards.length; i++) {
                if (state.storyboards[i].elementID !== action.payload.id) {
                    result.push(state.storyboards[i]);
                }
            }

            state.storyboards = result;
        },
        setScriptContent: (state, action) => {
            state.scriptContent = action.payload.scriptContent;
        },
        setCurrentState: (state, action) => {
            state.references = action.payload.data.workspace.references;
            state.storyboards = action.payload.data.workspace.storyboards;
            state.referenceElementID = action.payload.data.workspace.referenceElementID;
            state.scriptContent = action.payload.data.workspace.scriptContent;

            console.log('Setting current state.');
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setProjectID: (state, action) => {
            state.projectID = action.payload;
        },
        setRunEnvironment: (state, action) => {
            state.runEnvironment = action.payload;
        },
        setAPIEndpointBase: (state, action) => {
            state.apiEndpointBase = action.payload;
        },
        setReferenceElementPosition: (state, action) => {
            state.references = state.references.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.top = action.payload.top;
                    element.left = action.payload.left;
                }
                return element;
            });
        },
        setStoryboardElementPosition: (state, action) => {
            state.storyboards = state.storyboards.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.top = action.payload.top;
                    element.left = action.payload.left;
                }
                return element;
            });
        },
        setReferenceElementSize: (state, action) => {
            state.references = state.references.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.width = action.payload.width;
                    element.height = action.payload.height;
                }
                return element;
            });
        },
        setIsStateLoaded: (state, action) => {
            state.isStateLoaded = action.payload;
        },
        setLoginStatus: (state, action) => {
            state.loginStatus = action.payload;
        },
        setReferenceNotes: (state, action) => {
            state.references = state.references.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.notes = action.payload.notes;
                }
                return element;
            });
        },
        setStoryboardNotes: (state, action) => {
            state.storyboards = state.storyboards.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.notes = action.payload.notes;
                }
                return element;
            });
        },
        setShowReferencesCarousel: (state, action) => {
            state.showReferencesCarousel = action.payload;
        },
        setShowStoryboardsCarousel: (state, action) => {
            state.showStoryboardCarousel = action.payload;
        },
        setReferenceCarouselStartIndex: (state, action) => {
            const refID = action.payload;
            for (let i = 0; i < state.references.length; i++) {
                if (state.references[i].elementID === refID) {
                    state.referenceCarouselStartIndex = i;
                    break;
                }
            }
        },
        setStoryboardCarouselStartIndex: (state, action) => {
            const refID = action.payload;
            for (let i = 0; i < state.storyboards.length; i++) {
                if (state.storyboards[i].elementID === refID) {
                    state.storyboardCarouselStartIndex = i;
                    break;
                }
            }
        },
        setShowImageGeneratorEditor: (state, action) => {
            state.showImageGeneratorEditor = action.payload;
        },
        addTagToReferenceElement: (state, action) => {
            state.references = state.references.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.tags.push(action.payload.tag);
                }
                return element;
            });
        },
        deleteTagFromReferenceElement: (state, action) => {
            state.references = state.references.map((element) => {
                if (element.elementID === action.payload.id) {
                    element.tags = element.tags.filter((tag) => tag !== action.payload.tag);
                }
                return element;
            });
        },
        setWritingMode: (state, action) => {
            state.writingMode = action.payload;
        },
        saveState: (state, action) => {
            state.scriptContent = action.payload.scriptContent;
            const stateCopy = JSON.parse(JSON.stringify(state));
            AppCore.saveProject(stateCopy, state.apiEndpointBase, state.userEmail);
        },
        setImageGeneratorEditorCanvas: (state, action) => {
            state.imageGeneratorEditorCanvas = action.payload;
        }
    }
});

export const {
    addReferenceElement,
    addStoryboardElement,
    deleteReferenceElement,
    setReferenceElementSize,
    setCurrentState,
    setUserEmail,
    setRunEnvironment,
    setAPIEndpointBase,
    setReferenceElementPosition,
    setStoryboardElementPosition,
    setIsStateLoaded,
    setLoginStatus,
    setReferenceNotes,
    setShowReferencesCarousel,
    setReferenceCarouselStartIndex,
    setShowImageGeneratorEditor,
    addTagToReferenceElement,
    deleteTagFromReferenceElement,
    setWritingMode,
    saveState,
    setImageGeneratorEditorCanvas,
    deleteStoryboardElement,
    setStoryboardNotes,
    setStoryboardCarouselStartIndex,
    setShowStoryboardsCarousel,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;