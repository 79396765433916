
interface BlockToolConstructorOptions<T, U> {
    data: T;
    config: U;
    api: any;
    readOnly: any;
}

class BlockTool {
    api: any;
    readOnly = true;
    _CSS: any;
    _placeholder: any;
    _data: any;
    _element: any;
    _preserveBlank: any;
}

export const EditorPlugins = (function() {
    class Dialogue extends BlockTool {
        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'dialogue',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Dialogue.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            // const $aab = $('.add-audio-button.template').clone().removeClass('template');
            // $(div).append($aab);

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 'D' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6667 3.99996L3.33333 3.99999C2.22876 3.99999 1.33333 4.89542 1.33333 5.99999V12.6667C1.33333 13.7712 2.22876 14.6667 3.33333 14.6667H10C10.1768 14.6667 10.3464 14.7369 10.4714 14.8619L13.3333 17.7238V15.3333C13.3333 14.9651 13.6317 14.6667 13.9999 14.6667H16.6667C17.7712 14.6667 18.6667 13.7712 18.6667 12.6667V5.99996C18.6667 4.89539 17.7712 3.99996 16.6667 3.99996ZM3.33333 2.66666L16.6667 2.66663C18.5076 2.66662 20 4.15901 20 5.99996V12.6667C20 14.5076 18.5076 16 16.6667 16H14.6666V19.3332C14.6666 19.6029 14.5042 19.846 14.255 19.9492C14.0059 20.0524 13.7192 19.9953 13.5285 19.8047L9.72385 16H3.33333C1.49238 16 0 14.5076 0 12.6667V5.99999C0 4.15904 1.49238 2.66666 3.33333 2.66666Z" fill="#09090B"/></svg>',
                title: 'Dialogue',
            };
        }
    }


    class Character extends BlockTool {
        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'ce-character',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Character.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 'C' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3333 9.16665V9.99998C18.3249 11.6819 17.8077 13.3219 16.8498 14.7044C15.8918 16.0869 14.5379 17.1472 12.9661 17.7458C11.3943 18.3445 9.67813 18.4535 8.0432 18.0585C6.40828 17.6636 4.93114 16.7831 3.80597 15.5329C2.6808 14.2827 1.96027 12.7213 1.73911 11.054C1.51795 9.38663 1.8065 7.69138 2.56684 6.19109C3.32718 4.6908 4.5237 3.45571 5.99912 2.64818C7.47454 1.84064 9.15979 1.49847 10.8333 1.66665" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.66675 11.6666C6.66675 11.6666 7.91675 13.3333 10.0001 13.3333C12.0834 13.3333 13.3334 11.6666 13.3334 11.6666" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 7.5H7.50833" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 7.5H12.5083" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.3333 4.16663H18.3333" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.8333 1.66663V6.66663" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Character',
            };
        }
    }


    class Transition extends BlockTool {

        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'ce-transition',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Character.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 'T' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
                title: 'Transition',
            };
        }
    }

    class Action extends BlockTool {
        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'action',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Dialogue.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 'A' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.33325 9.16663V15.8333C3.33325 16.2753 3.50885 16.6992 3.82141 17.0118C4.13397 17.3244 4.55789 17.5 4.99992 17.5H14.9999C15.4419 17.5 15.8659 17.3244 16.1784 17.0118C16.491 16.6992 16.6666 16.2753 16.6666 15.8333V9.16663H3.33325Z" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.33324 9.16673L2.5999 6.77506C2.53561 6.5655 2.5133 6.34531 2.53423 6.12712C2.55516 5.90892 2.61892 5.69699 2.72188 5.50347C2.82483 5.30995 2.96494 5.13864 3.1342 4.99935C3.30345 4.86006 3.49852 4.75552 3.70824 4.69173L13.2749 1.77506C13.697 1.64488 14.1536 1.68762 14.5442 1.89388C14.9349 2.10014 15.2277 2.45305 15.3582 2.87506L16.0832 5.26673L3.33324 9.17506V9.16673Z" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.5 4.15833L8.31667 7.65832" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.88354 2.81677L12.7002 6.31677" stroke="#0F172A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Action',
            };
        }
    }

    class Parenthetical extends BlockTool {
        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'parenthetical',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Dialogue.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 'p' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
                title: 'Parenthetical',
            };
        }
    }

    class Shot extends BlockTool {
        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'shot',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Dialogue.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 's' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
                title: 'Shot',
            };
        }
    }

    class Lyrics extends BlockTool {
        /**
         * Default placeholder for Paragraph Tool
         *
         * @returns {string}
         * @class
         */
        static get DEFAULT_PLACEHOLDER() {
            return '';
        }

        /**
         * Render plugin`s main Element and fill it with saved data
         *
         * @param {object} params - constructor params
         * @param {ParagraphData} params.data - previously saved data
         * @param {ParagraphConfig} params.config - user config for Tool
         * @param {object} params.api - editor.js api
         * @param {boolean} readOnly - read only mode flag
         */
        constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<any, any>) {
            super();
            this.api = api;
            this.readOnly = readOnly;

            this._CSS = {
                block: this.api.styles.block,
                wrapper: 'lyrics',
            };

            if (!this.readOnly) {
                this.onKeyUp = this.onKeyUp.bind(this);
            }

            /**
             * Placeholder for paragraph if it is first Block
             *
             * @type {string}
             */
            this._placeholder = config.placeholder ? config.placeholder : Dialogue.DEFAULT_PLACEHOLDER;
            this._data = {};
            this._element = null;
            this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

            this.data = data;
        }

        /**
         * Check if text content is empty and set empty string to inner html.
         * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
         *
         * @param {KeyboardEvent} e - key up event
         */
        onKeyUp(e: KeyboardEvent) {
            if (e.code !== 'Backspace' && e.code !== 'Delete') {
                return;
            }

            const { textContent } = this._element;

            if (textContent === '') {
                this._element.innerHTML = '';
            }
        }

        /**
         * Create Tool's view
         *
         * @returns {HTMLElement}
         * @private
         */
        drawView() {
            const div = document.createElement('DIV');

            div.classList.add(this._CSS.wrapper, this._CSS.block);
            div.contentEditable = 'false';
            div.dataset.placeholder = this.api.i18n.t(this._placeholder);

            if (this._data.text) {
                div.innerHTML = this._data.text;
            }

            if (!this.readOnly) {
                div.contentEditable = 'true';
                div.addEventListener('keyup', this.onKeyUp);
            }

            return div;
        }

        /**
         * Return Tool's view
         *
         * @returns {HTMLDivElement}
         */
        render() {
            this._element = this.drawView();

            return this._element;
        }

        /**
         * Method that specified how to merge two Text blocks.
         * Called by Editor.js by backspace at the beginning of the Block
         *
         * @param {ParagraphData} data
         * @public
         */
        merge(data: any) {
            const newData = {
                text : this.data.text + data.text,
            };

            this.data = newData;
        }

        /**
         * Validate Paragraph block data:
         * - check for emptiness
         *
         * @param {ParagraphData} savedData — data received after saving
         * @returns {boolean} false if saved data is not correct, otherwise true
         * @public
         */
        validate(savedData: any) {
            if (savedData.text.trim() === '' && !this._preserveBlank) {
                return false;
            }

            return true;
        }

        /**
         * Extract Tool's data from the view
         *
         * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
         * @returns {ParagraphData} - saved data
         * @public
         */
        save(toolsContent: any) {
            return {
                text: toolsContent.innerHTML,
            };
        }

        /**
         * On paste callback fired from Editor.
         *
         * @param {PasteEvent} event - event with pasted data
         */
        onPaste(event: any) {
            const data = {
                text: event.detail.data.innerHTML,
            };

            this.data = data;
        }

        /**
         * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
         */
        static get conversionConfig() {
            return {
                export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
                import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
            };
        }

        /**
         * Sanitizer rules
         */
        static get sanitize() {
            return {
                text: {
                    br: true,
                },
            };
        }

        /**
         * Returns true to notify the core that read-only mode is supported
         *
         * @returns {boolean}
         */
        static get isReadOnlySupported() {
            return true;
        }

        /**
         * Get current Tools`s data
         *
         * @returns {ParagraphData} Current data
         * @private
         */
        get data() {
            if (this._element !== null) {
                const text = this._element.innerHTML;

                this._data.text = text;
            }

            return this._data;
        }

        /**
         * Store data in plugin:
         * - at the this._data property
         * - at the HTML
         *
         * @param {ParagraphData} data — data to set
         * @private
         */
        set data(data) {
            this._data = data || {};

            if (this._element !== null) {
                this.hydrate();
            }
        }

        /**
         * Fill tool's view with data
         */
        hydrate(){
            window.requestAnimationFrame(() => {
                this._element.innerHTML = this._data.text || '';
            });
        }

        /**
         * Used by Editor paste handling API.
         * Provides configuration to handle P tags.
         *
         * @returns {{tags: string[]}}
         */
        static get pasteConfig() {
            return {
                tags: [ 'l' ],
            };
        }

        /**
         * Icon and title for displaying at the Toolbox
         *
         * @returns {{icon: string, title: string}}
         */
        static get toolbox() {
            return {
                icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
                title: 'Lyrics',
            };
        }
    }

    return {
        Dialogue,
        Character,
        Transition,
        Action,
        Parenthetical,
        Shot,
        Lyrics
    };
})();




