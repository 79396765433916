import React from 'react';

import { IoIosColorPalette } from "react-icons/io";
import { GiTestTubes } from "react-icons/gi";
import { TbLassoPolygon } from "react-icons/tb";
import {Slider} from "./components/ui/slider";
import {Popover, PopoverContent, PopoverTrigger} from "./components/ui/popover";

interface GeneratedImageViewerCanvasToolbarProps {
    adjustHue: (value: number) => void;
    adjustSaturation: (value: number) => void;
    polygonLasso: () => void;
    mode: string; // Add this line
}

export const GeneratedImageViewerCanvasToolbar: React.FC<GeneratedImageViewerCanvasToolbarProps> = ({ adjustHue, adjustSaturation, polygonLasso, mode }) => {
    return (
        <div className="generated-image-viewer-canvas-toolbar">
            <Popover>
                <PopoverTrigger>
                    <button className={`drawing-canvas-toolbar-item ${mode === 'draw' ? 'active' : ''}`}>
                        <IoIosColorPalette/>
                        <div className={'drawing-canvas-toolbar-item-label'}>Hue</div>
                    </button>
                </PopoverTrigger>
                <PopoverContent className={'slider-popover'} align={'start'}>
                    <Slider
                        defaultValue={[0]}
                        max={1}
                        min={-1}
                        step={0.01}
                        className={"w-[100%]"}
                        onValueChange={(value) => {
                            adjustHue(value[0]);
                        }}
                    />
                </PopoverContent>
            </Popover>
            <Popover>
                <PopoverTrigger>
                    <button className={`drawing-canvas-toolbar-item ${mode === 'erase' ? 'active' : ''}`}>
                        <GiTestTubes/>
                        <div className={'drawing-canvas-toolbar-item-label'}>Saturation</div>
                    </button>
                </PopoverTrigger>
                <PopoverContent className={'slider-popover'} align={'start'}>
                    <Slider
                    defaultValue={[0]}
                    max={1}
                    min={-1}
                    step={0.01}
                    className={"w-[100%]"}
                    onValueChange={(value) => {
                        adjustSaturation(value[0]);
                    }}
                />
                </PopoverContent>
            </Popover>
            <button onClick={polygonLasso} className={`drawing-canvas-toolbar-item ${mode === 'erase' ? 'active' : ''}`}><TbLassoPolygon /> <div className={'drawing-canvas-toolbar-item-label'}>Lasso</div></button>
        </div>
);
};