import React, {useEffect, useState} from "react";
import {Card, CardContent} from "src/components/components/ui/card";
import {Button} from "src/components/components/ui/button"
import { FaRegTrashAlt } from "react-icons/fa";
import {useDispatch} from "react-redux";
import {
    deleteStoryboardElement,
    setStoryboardNotes,
    setStoryboardCarouselStartIndex, setShowStoryboardsCarousel, setStoryboardElementPosition
} from "../store/slices/workspaceSlice";
import { Rnd } from "react-rnd";
import {Textarea} from "./components/ui/textarea";
import { CiShoppingTag } from "react-icons/ci";


export class StoryboardElement {
    type = '';
    elementID = '';
    srcPrompt = '';
    top = 0;
    left = 10;
    width = 300;
    height = 186;
    transform = '';
    scriptElementId = '';
    data = '';
    filename = '';
    notes = '';
    tags:Array<string> = [];
}


function StoryboardComponent(props: StoryboardElement) {

    const dispatch = useDispatch();

    const [componentNotes, setComponentNotes] = useState(props.notes);

    const [mouseOver, setMouseOver] = useState(false);

    useEffect(() => {
        setComponentNotes(props.notes);
    }, [props.notes]);

    function deleteRefElementHandler() {
        console.log('deleteStoryboardElement: ' + props.elementID);
        dispatch(deleteStoryboardElement({id: props.elementID}));
    }

    function handleNotesBlur(e: any) {
        dispatch(setStoryboardNotes({id: props.elementID, notes: e.target.value}));
    }

    function showStoryboardCarousel() {
        dispatch(setStoryboardCarouselStartIndex(props.elementID));
        dispatch(setShowStoryboardsCarousel(true));
    }

    function handleMouseOver() {
        setMouseOver(true)
    }

    function handleMouseOut() {
        setMouseOver(false)
    }

    return (
            <Rnd key={props.elementID}
                 position={{x: props.left, y: props.top}}
                 size={{width: props.width, height: props.height}}
                 lockAspectRatio={true}
                 enableResizing={{top: false, right: false, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false}}
                 bounds={'window'}

                 onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={mouseOver ? {zIndex: 15} : {}}

                 onDragStop={(e, d) => {
                     e.preventDefault();
                     e.stopPropagation();
                     const top = d.y;
                     const left = d.x;
                     dispatch(setStoryboardElementPosition({id: props.elementID, left: left, top: top}));
                 }}

                 dragAxis = {'y'}
            >

                <Card className="w-[300px] storyboard-component">
                    <CardContent>
                        <div className="generated-image storyboard">
                            { props.tags && props.tags.length > 0 ? <div className={'has-tags-indicator'}><CiShoppingTag></CiShoppingTag></div> : null }
                            {/*<Button className="edit-image-button" onClick={showStoryboardCarousel}>*/}
                            {/*    <FaRegEdit></FaRegEdit>*/}
                            {/*</Button>*/}
                            <Button className="delete-image-button" onClick={deleteRefElementHandler}>
                                <FaRegTrashAlt></FaRegTrashAlt>
                            </Button>
                            <img src={decodeURIComponent(props.data)} style={{width: "100%;"}} alt=""></img>
                            <div className={"storyboard-notes-container"}>
                                <Textarea className={'storyboard-notes-textarea'}
                                          onBlur={handleNotesBlur}
                                          placeholder={'Click to add notes'}
                                          value={componentNotes}
                                          onChange={(e) => setComponentNotes(e.target.value)}>
                                    {componentNotes}
                                </Textarea>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                {/*</ResizableBox>*/}
            </Rnd>
    );
}

export default StoryboardComponent;